import axios from "axios";
import { toast } from "react-toastify";
const API_URL =
  "https://hub.advansystelecom.com/generalapiv12/api/general";
const AUTH =
  "https://hub.advansystelecom.com/generalapiv12/api/dashboard/token";

const CreateRequest = (procName, params, API, tablesNames, isFormData) => {
  return new Promise((resolve, reject) => {
    const requestConfig = {
      headers: {},
    };
    if (!procName.includes("Unauthorized")) {
      requestConfig.headers.Authorization = "Bearer " + localStorage.getItem("token");
    }

    axios.post(
      API || API_URL,
      isFormData
        ? params
        : {
          ConnectionStringName: "ZakatCommittee",
          StoredProcedureName: procName,
          SpParams: params,
          TablesNames: tablesNames,
        },
      requestConfig
    )
      .then((response) => resolve(response))
      .catch((error) => {
        console.log(error);
        if (
          error.response?.data["error-message"] == "30" ||
          error.response?.data["error-message"] == "20"
        ) {
          localStorage.clear();
          window.location.reload();
        }
        toast.error(error.response?.data?.Message || error);
        reject(error);
      });
  });
};

export const CreateInquiriess = (params) => {
  return CreateRequest(
    "Unauthorized.CreateInquiriess",
    {
      ...params,
    }
  );
};

export const CreateDonation = (params) => {
  return CreateRequest(
    "Unauthorized.CreateDonation",
    {
      ...params,
    }
  );
};

export const CreateInkindDonation = (params) => {
  return CreateRequest(
    "Unauthorized.CreateInkindDonation",
    {
      ...params,
    }
  );
};





