import React, { useState } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import { CreateDonation } from "../../API";
import "./style.css";

const BankCardDonate = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [donateValue, setDonateValue] = useState("");
  const [loading, setLoading] = useState(false);

  const phoneRegex = /^01[0125][0-9]{8}$/;

  const createCheckoutSession = async () => {
    const url = "https://hub.advansystelecom.com/generalapiv12/api/paymentNBE/createCheckoutSession";

    const body = {
      Amount: parseFloat(donateValue),
    };

    try {
      const response = await axios.post(url, body, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        const responseData = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
        const sessionId = responseData.session.id;
        const merchant = responseData.merchant;
        const result = responseData.result;
        const updateStatus = responseData.session.updateStatus;
        const version = responseData.session.version;
        const successIndicator = responseData.successIndicator;
        const uniqueId = responseData.uniqueId;

        localStorage.setItem('sessionId', sessionId);
        localStorage.setItem('uniqueId', uniqueId);

        CreateDonation({
          "@Name": name,
          "@Phone": phone,
          "@Merchant": merchant,
          "@Result": result,
          "@SessionID": sessionId,
          "@UpdateStatus": updateStatus,
          "@Version": version,
          "@SuccessIndicator": successIndicator,
          "@Amount": parseFloat(donateValue),
          "@UniqueId": uniqueId
        }).then((response) => {
          toast.success('قم بالدفع لإتمام عملية التبرع');
          window.location.href = `/checkout.html`;
          setLoading(false);
        }).catch((error) => {
          console.log(error);
        });

      } else {
        console.error("Error:", response.statusText);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error while calling the backend API:", error);
    }
  };

  const handleSubmit = () => {
    if (!donateValue || !name || !phone) {
      toast.error("يرجى ملء جميع الحقول");
      return;
    }

    if (!phoneRegex.test(phone)) {
      toast.error("يرجى إدخال رقم هاتف مصري صالح");
      return;
    }
    if (donateValue <= 0) {
      toast.error("يرجى إدخال قيمة تبرع صحيحة");
      return;
    }
    setLoading(true);
    createCheckoutSession();
  };

  return (
    <div className="BankCardDonate">
      <div className="donation-heading">بطاقة بنكية</div>
      <div className="titleUnderLine underLineTitle"></div>
      <div className="BankCardDonateContent">
        <span>
          يمكنك التبرع بأى من البطاقات البنكية فيزا, ماستر كارد, الخ..
        </span>
      </div>
      <div className="donatecontainer">
        <div className="content">
          <div className="donation-content">
            <div className="donateForm">
              <input
                className="other"
                type="text"
                placeholder="اسم المتبرع"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                className="other"
                type="text"
                placeholder="رقم الهاتف"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <input
                className="other"
                type="number"
                placeholder="قيمة التبرع"
                value={donateValue}
                onChange={(e) => setDonateValue(e.target.value)}
                min={1}
              />
            </div>

            <button
              className="donate-button"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? (
                <span className="spinner-border" role="status"></span>
              ) : (
                "اتمام التبرع"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankCardDonate;
