import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { CreateInquiriess } from "../../API";
import './style.css'

const Newsletter = (props) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const isValidEmail = (email) => {
        // Regular expression for validating email addresses
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    }

    const submitHandler = (e) => {
        e.preventDefault();
        // Check if email is valid
        if (!isValidEmail(email)) {
            toast.error('الرجاء إدخال بريد إلكتروني صالح');
            return;
        }
        setLoading(true);
        CreateInquiriess(
            {
                "@Email": email,
                "@Content": message
            }
        ).then((response) => {
            toast.success('تم إرسال الاستفسار بنجاح');
            setEmail('');
            setMessage('');
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    return (
        <section className={`news-letter-section ${props.NewsClass}`}>
            <div className="container">
                <div className="news-letter-wrap">
                    <div className="row justify-content-center">
                        <div className="col col-lg-10 col-md-8">
                            <div className="newsletter">
                                <span className='contactUsEmailTitle'>تواصل معنا</span>
                                <p>بامكانك مراسلتنا عن طريق البريد الالكترونى بالمقترحات او الشكاوى</p>
                                <div className="newsletter-form">
                                    <form onSubmit={submitHandler}>
                                        <div>
                                            <input
                                                type="text"
                                                placeholder="ادخل البريد الالكترونى"
                                                className="form-control"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            <textarea
                                                id="messageContent"
                                                placeholder='الرسالة'
                                                value={message}
                                                onChange={(e) => setMessage(e.target.value)}
                                            ></textarea>
                                        </div>
                                        <button className="bigCursor" type="submit" disabled={loading}>
                                        {loading ? (
                                                <span className="spinner-border" role="status"></span>
                                            ) : (
                                                "إرسال"
                                            )}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Newsletter;
